import React, { createContext, useState, useRef } from "react"

const defaultState = {
  initialAnimation: true,
  position: "",
  state: "",
  handleMouseHover: "",
}

export const CursorContext = createContext(defaultState)

export const CursorProvider = ({ children }) => {
  const [mousePosition, setMousePosition] = useState({
    outerX: 600,
    outerY: 600,
    innerX: 623,
    innerY: 623,
  })
  const [cursorState, setCursorState] = useState([])
  const initialAnimation = useRef(true)

  const handleMouseHover = (e, stateType) => {
    if (e.type === "mouseenter") {
      setCursorState(prevState => [...prevState, stateType])
    } else if (e.type === "mouseleave") {
      setCursorState(prevState =>
        prevState.filter(state => state !== stateType)
      )
    } else {
      return
    }
  }

  return (
    <CursorContext.Provider
      value={{
        initialAnimation: initialAnimation,
        position: [mousePosition, setMousePosition],
        state: [cursorState, setCursorState],
        handleMouseHover: handleMouseHover,
      }}
    >
      {children}
    </CursorContext.Provider>
  )
}
