import React from "react"
import { AnimatePresence } from "framer-motion"
import { CursorProvider } from "./src/context/cursorContext"
import "./src/styles/preloader.css"

export const wrapPageElement = ({ element }) => (
  <CursorProvider>
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  </CursorProvider>
)

export const shouldUpdateScroll = () => {
  return false
}
